import { render, staticRenderFns } from "./academic_schdual_refereesystem.vue?vue&type=template&id=21235cf7&scoped=true&"
import script from "./academic_schdual_refereesystem.vue?vue&type=script&lang=js&"
export * from "./academic_schdual_refereesystem.vue?vue&type=script&lang=js&"
import style0 from "./academic_schdual_refereesystem.vue?vue&type=style&index=0&id=21235cf7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21235cf7",
  null
  
)

export default component.exports